<template>
  <div class="shop-add">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <page-form v-if="isShow" @manError="manError"></page-form>
  </div>
</template>

<script lang="js">


import { defineComponent, reactive, onBeforeMount, ref, onUnmounted } from 'vue'
import PageForm from './cpns/page-form/page-form.vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'

export default defineComponent({
  props: {

  },
  components: {
    PageForm,
    Breadcrumb
  },
  setup() {


    const isShow = ref(false)


    const manError = () => {
      isShow.value = false

      setTimeout(() => {
        isShow.value = true
      }, 10);
    }



    onBeforeMount(() => {
      isShow.value = true
      // console.log('挂载');
    })
    onUnmounted(() => {
      isShow.value = false
      // console.log('销毁');
    })

    return {
      isShow,
      breadcrumbList,
      manError,

    }

  }
})
</script>

<style scoped lang="less">
</style>




