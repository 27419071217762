<template>
  <div class="room-form">
    <el-form-item label-width="90px" label="房间编号：" prop="name">
      <el-input v-model="roomRuleForm.roomNo" />
    </el-form-item>
    <el-form-item class="bedNum" label-width="90px" label="床位编号：" prop="name">
      <el-input placeholder="以逗号分隔" v-model="roomRuleForm.bedQty"></el-input>
    </el-form-item>

    <div class="opt">
      <div @click="handleRemoveClick" v-if="!isFirst" class="remove">删除</div>
      <!-- <div @click="handleUpdateClick" v-if="roomRuleForm.isOld" class="update">保存</div> -->
      <!-- <div @click="handleSetState(2)" v-if="roomRuleForm.status == 1">停用</div>
      <div @click="handleSetState(1)" v-if="roomRuleForm.status == 2">启用</div> -->
    </div>
  </div>
</template>

<script lang="js">
import store from '@/store'
import { defineComponent, reactive, ref } from 'vue'
import { COMPANY_ID } from '@/common/constant'
export default defineComponent({
  props: {
    roomRuleForm: {
      type: Object,
      default() {
        return {}
      }
    },
    isFirst: {
      type: Boolean,
      default: false
    },
    currentShopId:{
      type:[String,Number],
      default:''
    }
  },
  components: {

  },
  emits: ['handleRemoveClick'],
  setup(props, { emit }) {

    const handleSetState = (type) => {
      store.dispatch('shop/removeShopRoomAction', {
        roomId: props.roomRuleForm.rommId,
        companyId: COMPANY_ID,
        status: type
      })
      // console.log(props.currentShopId)
      setTimeout(() => {
        store.dispatch('shop/getShopBedListAction', {
          shopId: props.currentShopId
        })
      }, 800)
      
    }
    const handleRemoveClick = () => {
      if (props.roomRuleForm.isOld) {
        store.dispatch('shop/removeShopRoomAction', {
          roomId: props.roomRuleForm.rommId,
          companyId: COMPANY_ID,
          status: 9
        })
        // console.log(1);
      }
      emit('handleRemoveClick')
    }

    const handleUpdateClick = () => {
      let obj = {
        roomId: props.roomRuleForm.rommId,
        companyId: COMPANY_ID,
        roomNo: props.roomRuleForm.roomNo,
        bedQty: props.roomRuleForm.bedQty.replace(/，/g, ",")
      }

      store.dispatch('shop/updateShopRoomAction', obj)
    }

    return {
      handleSetState,
      handleUpdateClick,
      handleRemoveClick,
      COMPANY_ID
    }

  }
})
</script>

<style scoped lang="less">
.room-form {
  display: flex;

  &:deep(.el-input) {
    min-width: 100px !important;
  }

  .bedNum {
    margin-left: 20px;
  }

  .opt {
    display: flex;
    min-width: 138px;
    max-width: 138px;

    div {
      white-space: nowrap;
      margin: 0 10px;
      text-align: center;
      line-height: 40px;
      font-size: 13px;
      color: rgb(64, 158, 255);
      cursor: pointer;
    }
  }
}
</style>
