<template>
  <div @click="handleElFormClick" class="page-form">
    <div class="left">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="updateShopRule" label-width="120px" class="demo-ruleForm">
        <div class="title">基础信息</div>
        <el-form-item label="门店区域：" prop="shopProv">
          <div class="shop-area">
            <el-form-item>
              <el-select v-model="ruleForm.shopProv" class="m-2" placeholder="请选择所在省" size="large"
                @change="handleShopProvChange">
                <el-option v-for="item in provinceList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="shopCity">
              <el-select v-model="ruleForm.shopCity" class="m-2" placeholder="请选择所在市" size="large"
                @change="handleshopCityChange">
                <el-option v-for="item in shopCityList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item class="detail-adress-wrap" label="详细地址：" prop="shopAddr">
          <el-input @clear="handleShopAddrClear" clearable @input="handleDetailAdressInput"
            @focus="handleDttailAddressFocus" @blur="handleDetailAddressBlur" v-model="ruleForm.shopAddr"
            placeholder="请输入详细地址" />
          <div v-show="mapForm.isShowResultView" id="address-result">
            <template :key="item" v-for="item in mapForm.addresResult">
              <div @click="addressListClick(item)">
                <el-icon>
                  <position />
                </el-icon>
                {{ item.title }}-{{ item.address }}
              </div>
            </template>
          </div>
        </el-form-item>
        <el-form-item label="门店名称：" prop="shopName">
          <el-input v-model="ruleForm.shopName" placeholder="请输入门店名称" />
        </el-form-item>
        <div class="title">营业信息</div>
        <el-form-item label="门店介绍：" prop="shopIntro">
          <el-input :maxlength="200" show-word-limit v-model="ruleForm.shopIntro" :rows="4" type="textarea"
            placeholder="请输入门店介绍" />
        </el-form-item>
        <el-form-item label="营业时间：" prop="shopRuntime">
          <div class="run-time">
            <el-time-select v-model="ruleForm.runStartTime" :max-time="ruleForm.runEndTime" class="mr-4"
              placeholder="开始时间" start="00:00" step="00:15" end="23:15" />
            <el-time-select v-model="ruleForm.runEndTime" :min-time="ruleForm.runStartTime" placeholder="结束时间"
              start="00:15" step="00:15" end="24:00" />
          </div>
        </el-form-item>
        <el-form-item label="联系电话：" prop="shopPhone">
          <el-input v-model="ruleForm.shopPhone" placeholder="请输入联系电话" />
        </el-form-item>
        <el-form-item label="门店类型：" prop="shopKind">
          <el-select v-model="ruleForm.shopKind" class="m-2" placeholder="请选择所在省市区" size="large">
            <el-option v-for="item in shopType" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="门店照片：">
          <upload-img :file-list="imgUploadFileList" ref="imgUploadRef" @handleUploadSuccess="handleImgUploadSuccess"
            @handleUploadEmpty="handleImgUploadRemove" :limit="1"></upload-img>
          <div class="tips">建议尺寸：800*600像素，你可以拖拽图片调整顺序，最多上传1张</div>
        </el-form-item>
        <div class="title">坪效信息</div>
        <el-form-item label="开业时间：" prop="openDate">
          <el-date-picker v-model="ruleForm.openDate" type="date" placeholder="请输入开业时间" value-format="YYYY-MM-DD"></el-date-picker>
        </el-form-item>
        <el-form-item label="建筑面积：" prop="shopSquare">
          <el-input v-model="ruleForm.shopSquare" placeholder="请输入建筑面积">
            <template #suffix>| 平方米</template>
          </el-input>
        </el-form-item>
        <el-form-item label="使用面积：" prop="shopUseSquare">
          <el-input v-model="ruleForm.shopUseSquare" placeholder="请输入使用面积">
            <template #suffix>| 平方米</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <div class="tips">
            <div>*床位编号将自动以数字编号</div>
            <div @click="handleAddRoomClick">+新增房间</div>
          </div>
        </el-form-item>

        <div class="room">
          <template :key="item" v-for="(item, index) in roomFormList">
            <room-form @handleRemoveClick="handleRoomRemoveClik(index)" :isFirst="index == 0" :currentShopId="currentShopId" :roomRuleForm="item">
            </room-form>
          </template>
        </div>
        <div class="submit">
          <el-button @click="handleSaveShopInfoClick(ruleFormRef)" type="primary">保存门店信息</el-button>
          <div class="submit-tips">注：门店创建后,需手动上线,用户才能浏览</div>
        </div>
      </el-form>
    </div>
    <div class="right">
      <div id="map"></div>
      <div class="tips">也可以直接拖动图标,标记门店位置</div>
    </div>
  </div>
</template>

<script lang="js">
import UploadImg from '@/components/page-upload/upload-img/upload-img.vue'
import { defineComponent, reactive, ref, onMounted, nextTick, computed, watch, onUnmounted, onBeforeUpdate } from 'vue'
import { map } from '@/utils/map'
import { debounce } from 'lodash'
import { getCityList } from '@/service/main/shop'
import { useStore } from 'vuex'
import { getKeywordList } from '@/service/main/shop'
import { shopType, shopBelongList } from '../../config'
import { COMPANY_ID } from '@/common/constant'
import { useRoute } from 'vue-router'
import { UPLOAD_BASE_URL } from '@/common/constant'
import RoomForm from './cpns/room-form/room-form.vue'
import { ElMessage } from 'element-plus'
import { updateShopRule } from '../../config'
import { saveShopPic } from '@/service/main/shop'
import { tips } from '@/utils/tips'
import { emit } from 'process'
import { addScheduleWeekSaveShift } from '@/service/main/staff'


export default defineComponent({
  props: {

  },
  components: {
    UploadImg,
    RoomForm
  },
  emits: ['manError'],
  setup() {

    const route = useRoute()
    const address = ref('')
    const store = useStore()
    const mapForm = reactive({
      x: '31.235929',
      y: '121.48054',
      addresResult: [],
      isShowResultView: false
    })


    const imgUploadFileList = ref([])

    const ruleForm = reactive({
      // companyID: COMPANY_ID,
      // shopDistrict: "",
      shopProv: "",
      shopCity: "",
      // shoparea: "",
      shopAddr: "",
      shopName: "",
      shopKind: "",
      shopRuntime: "",
      shopPhone: "",
      shopIntro: "",
      openDate: "",
      shopSquare: "",
      shopUseSquare: "",
      lng: "",
      lat: "",
      // shopVideo: "",
      runStartTime: '',
      runEndTime: ''
    })



    const emptyRuleForm = () => {
      for (let key in ruleForm) {
        if (key != 'pic') {
          ruleForm[key] = ''
        } else {
          ruleForm[key] = []
        }
      }
    }


    const shopDetail = computed(() => store.state.shop.shopDetail)
    watch(shopDetail, (n) => {
      if (n != null) {

        mapForm.x = n.lat
        mapForm.y = n.lng
        ruleForm.lat = n.lat
        ruleForm.lng = n.lng
        ruleForm.shopName = n.shopName
        ruleForm.shopAddr = n.shopAddr
        ruleForm.shopPhone = n.shopPhone
        ruleForm.shopRuntime = n.shopRuntime
        ruleForm.openDate = n.openDate
        ruleForm.shopIntro = n.shopIntro
        ruleForm.shopKind = n.shopKind.toString()
        ruleForm.shopSquare = n.shopSquare
        ruleForm.shopUseSquare = n.shopUseSquare
        ruleForm.shopProv = n.shopProv
        handleShopProvChange(n.shopProv)
        ruleForm.shopCity = n.shopCity
        handleshopCityChange(n.shopCity)
        // ruleForm.shoparea = n.shopArea
        // ruleForm.shopDistrict = String(n.shopDistrict)
        let shopRuntime = (n.shopRuntime).split('-')
        ruleForm.runStartTime = shopRuntime[0]
        ruleForm.runEndTime = shopRuntime[1]
        if (mapForm.x > 90 || mapForm.x < -90) {
          mapForm.x = 31.235929
          mapForm.y = 121.48054
          ruleForm.lat = 31.235929
          ruleForm.lng = 121.48054
        }
        var location = new TMap.LatLng(mapForm.x, mapForm.y);
        map.setCenter(location);
        imgUploadFileList.value=[{url: n.picture[0]}]
        roomFormList.value = []
        if (n.roomList.length) {
          n.roomList.forEach(item => {
            roomFormList.value.push({
              roomNo: item.roomNo,
              bedQty: item.bedQty,
              rommId: item.roomId
            })
          })
      }
      }

    })



    const handleDetailAdressInput = debounce(async (value) => {
      // 当输入框为空时
      if (value.length == 0) {
        handleShopAddrClear()
        return
      }

      if (ruleForm.lng != '121.48054') {
        return
      }

      if (value.trim().length == 0) {
        mapForm.isShowResultView = false
      }

      suggest
        .getSuggestions({ keyword: value, location: map.getCenter() })
        .then((result) => {
          mapForm.addresResult = result.data
          if (result.data.length > 0) {
            mapForm.isShowResultView = true
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    }, 750)


    const roomFormList = ref([])

    const handleAddRoomClick = () => {

      roomFormList.value.push({
        roomNo: '',
        bedQty: ''
      })
    }

    const handleRoomRemoveClik = (index) => {
      roomFormList.value.splice(index, 1)
    }


    const shopImgList = ref([])
    const handleImgUploadRemove = (lastUrl) => {
      // console.log(lastUrl,"lastUrl");
      imgUploadFileList.value = []
    }

    const addPicListRecord = ref([])
    const handleImgUploadSuccess = async (res) => {
      // console.log(res);
      imgUploadFileList.value = [{url:res.response.data.fileName}]

    }



    const handleDetailAddressBlur = (e) => {
      if (e.target.value.trim().length == 0) {
        mapForm.isShowResultView = false
      }

    }

    const handleDttailAddressFocus = (e) => {
      if (e.target.value.trim().length == 0) {
        mapForm.isShowResultView = false
      }

    }

    const provinceList = ref([])
    const shopCityList = ref([])
    const shopareaList = ref([])
    const currentCity = ref('')
    const city = async () => {
      const cityList = await getCityList()
      provinceList.value = cityList.result[0]
      // console.log(provinceList.value)
    }
    city()

    const handleShopProvChange = async (id) => {
      const cityList = await getCityList(id)
      shopCityList.value = cityList.result[0]
      // console.log(shopCityList.value);
      let ele = provinceList.value.find(item => item.id == id)
      if (ele != undefined) {
        currentCity.value = ele.name
      }



    }
    const handleshopCityChange = async (id) => {
      const cityList = await getCityList(id)
      shopareaList.value = cityList.result[0]
      let ele = shopCityList.value.find(item => item.id == id)
      if (ele != undefined) {
        currentCity.value = ele.name
      }



    }

    const ruleFormRef = ref()
    const handleSaveShopInfoClick = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate((valid, fields) => {
        if (valid) {
          if (roomFormList.value.length == 0) {
            ElMessage({
              message: '最少添加一个床位!',
              type: 'warning',
            })
            return
          }
          if (!(imgUploadFileList.value&&imgUploadFileList.value.length)) {
            ElMessage({
              message: '最少上传一张门店照片!',
              type: 'warning',
            })
            return
          }
          let flag = true
          let roomList = roomFormList.value
          // console.log(roomList);
          roomList.forEach(ele => {
            const arr = roomList.filter(item => item.roomNo == ele.roomNo)
            // console.log(arr);
            if (arr.length > 1) {
              ElMessage({
                message: '房间编号不得重复！',
                type: 'warning',
              })
              flag = false
            }
          })
          if (flag) {
            store.dispatch('shop/updateShopInfoAction', { ...ruleForm,shopId: currentShopId.value,roomFormList: roomFormList.value,pic:imgUploadFileList.value})
          }

        } else {
          ElMessage({
            message: '带*为必填项，请正确填写数据！',
            type: 'warning',
          })

        }
      })

    }


    const mapView = ref('')



    let map, search, suggest, markers, geocoder
    const initTxMap = () => {
      // console.log(mapForm.x, mapForm.y);
      map = new TMap.Map('map', {
        rotation: 20, //设置地图旋转角度
        pitch: 30, //设置俯仰角度（0~45）
        zoom: 16, //设置地图缩放级别
        center: new TMap.LatLng(mapForm.x, mapForm.y),
      });
      search = new TMap.service.Search({ pageSize: 10 }); // 新建一个地点搜索类
      geocoder = new TMap.service.Geocoder(); // 新建一个正逆地址解析类
      suggest = new TMap.service.Suggestion({
        // 新建一个关键字输入提示类
        pageSize: 10, // 返回结果每页条目数
        region: '上海', // 限制城市范围
        regionFix: true, // 搜索无结果时是否固定在当前城市
      });
      markers = new TMap.MultiMarker({
        map: map,
        geometries: [],
      });

      map.on("click", (evt) => {
        markers.setGeometries([]);
        ruleForm.lng = evt.latLng.lng
        ruleForm.lat = evt.latLng.lat
        var location = new TMap.LatLng(evt.latLng.lat, evt.latLng.lng);
        map.setCenter(location);
        markers.updateGeometries([
          {
            id: '0', // 点标注数据数组
            position: evt.latLng,
          },
        ]);

        geocoder
          .getAddress({ location: location }) // 将给定的坐标位置转换为地址
          .then((result) => {
            ruleForm.shopAddr = result.result.formatted_addresses.recommend + '-' + result.result.address;
            // 显示搜索到的地址
          });
      });
    }

    onMounted(() => {
      initTxMap()
    })


    const addressListClick = (item) => {
      // console.log(item);
      ruleForm.lng = item.location.lng
      ruleForm.lat = item.location.lat
      mapForm.isShowResultView = false
      if (ruleForm.lng != '121.48054') {
        ruleForm.shopAddr = item.title + '' + item.address
      }

      map.setCenter(item.location);
      markers.setGeometries([]);
      // console.log(item.location);
      markers.updateGeometries([
        {
          id: '0', // 点标注数据数组
          position: item.location,
        },
      ]);


    }

    const shopImgFileList = ref([])
    const uploadShopImgRef = ref(null)
    const handleUploadChange = (res, file, fileList) => {
      ruleForm.pic.push(res.data.fileName)
    }

    const currentShopId = ref(route.query.shopId)
    watch(() => route.path, (n) => {
      currentShopId.value = route.query.shopId
      // console.log(route.query.shopId);

      if (n == '/main/shop/update') {

        initPage()

      } else {
        map.destroy();
      }
    }, {
      deep: true
    })
    const initPage = () => {
      store.dispatch('shop/getShopDetailAction', currentShopId.value)
    }
    initPage()




    const handleElFormClick = () => {
      mapForm.isShowResultView = false
    }

    const hadleUploadImgExceed = () => {
      ElMessage.error('最多上传5张图片!')
    }

    const handleShopAddrClear = () => {
      ruleForm.lng = '121.48054'
      addressListClick({
        location: {
          lng: '121.48054',
          lat: '31.235929'
        }
      })
    }


    return {
      handleShopAddrClear,
      ruleFormRef,
      mapView,
      handleElFormClick,
      shopBelongList,
      handleRoomRemoveClik,
      address,
      handleDetailAdressInput,
      mapForm,
      addressListClick,
      handleDetailAddressBlur,
      handleDttailAddressFocus,
      provinceList,
      shopCityList,
      ruleForm,
      handleShopProvChange,
      handleshopCityChange,
      shopareaList,
      handleSaveShopInfoClick,
      currentCity,
      shopType,
      handleUploadChange,
      shopImgFileList,
      emptyRuleForm,
      uploadShopImgRef,
      currentShopId,
      shopDetail,
      imgUploadFileList,
      handleImgUploadSuccess,
      handleImgUploadRemove,
      shopImgList,
      handleAddRoomClick,
      roomFormList,
      updateShopRule,
      addPicListRecord

    }

  }
})
</script>

<style scoped lang="less">
.page-form {
  margin: 20px;
  display: flex;
  background-color: #fff;
  padding: 20px;

  .run-time {
    display: flex;
    justify-content: space-between;

    &:deep(.el-select) {
      max-width: 340px;
    }
  }

  .tips {
    color: rgba(80, 80, 80, 1);
    font-size: 12px;
    margin-top: 20px;
  }

  .left,
  .right {
    flex: 1;

    .title {
      position: relative;
      padding-left: 12px;
      margin: 24px 0;
      margin-left: 20px;

      &::before {
        content: "";
        position: absolute;
        top: -2px;
        left: 0;
        width: 6px;
        height: 22px;
        background-color: rgba(42, 130, 228, 1);
      }
    }
  }

  .submit {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .submit-tips {
      margin-top: 10px;
      color: rgba(165, 165, 165, 1);
      font-size: 12px;
    }
  }

  .right {
    margin-left: 20px;

    #map {
      width: 350px;
      height: 350px;
      margin-top: 66px;
    }


  }

  .left {
    .shop-area {
      display: flex;
    //   justify-content: space-between;

      &:deep(.el-select) {
        margin-right: 10px;
      }
    }

    .detail-adress-wrap {
      position: relative;

      #address-result {
        width: 100%;
        position: absolute;
        top: 45px;
        left: 0;
        z-index: 999;
        background-color: #fff;
        border: 1px solid var(--borderColor);
        padding: 10px;
      }
    }

    .tips {
      display: flex;
      justify-content: space-between;
      color: rgba(80, 80, 80, 1);
      background-color: rgba(239, 239, 239, 1);
      font-size: 14px;
      padding: 0 10px;
    }

    .room {
      // display: flex;
      // justify-content: space-between;
      padding-left: 120px;
    }
  }

  &:deep(.el-select) {
    width: 100%;
  }

  &:deep(.el-input) {
    width: 100%;
  }
}
</style>
